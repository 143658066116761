import React from 'react';
import { inject } from 'mobx-react';
import { Button, Intent, Dialog } from '@blueprintjs/core';
import { ILocalizedProps } from '@elements/localization';
import { LykkeForm, LabelWithTextInput } from '@elements/lykke-html/form/LykkeForm';
import { FrontendBadRequest } from '@client/backoffice/CustomErrors';

interface Props extends ILocalizedProps {
  open?: boolean;
  loading?: boolean;
  errors?: FrontendBadRequest;
  onClose();
  onSubmit(tickFormulaId: string);
}

interface State {
  validationError: string;
  tickFormulaId: string;
}

@inject('i18n')
export class AddTickFormulaDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { tickFormulaId: '', validationError: '' };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.open !== nextProps.open) {
      this.setState({ tickFormulaId: '', validationError: '' });
    }
  }

  private get loc() {
    return this.props.i18n.BrokerSettingsView.TickFormulasTab;
  }

  private get validationErrorsLoc() {
    return this.props.i18n.FormValidationErrors;
  }

  private handleClose = () => {
    const { onClose } = this.props;
    this.setState({ tickFormulaId: '', validationError: '' });
    onClose();
  }

  private handleChange = (tickFormulaId: string) => {
    this.setState({ tickFormulaId, validationError: '' });
  }

  private validate = () => {
    const { tickFormulaId } = this.state;
    const tickFormulaIdRegex = /^[a-zA-Z][a-zA-Z0-9_]*/;

    if (tickFormulaId === '') {
      this.setState({ validationError: this.validationErrorsLoc.RequiredField });
      return false;
    }

    if (!tickFormulaIdRegex.test(tickFormulaId)) {
      this.setState({ validationError: this.loc.Errors.Id });
      return false;
    }

    return true;
  }

  private handleSubmit = () => {
    if (this.validate()) {
      this.props.onSubmit(this.state.tickFormulaId);
    }
  }

  public render() {
    const { open, loading } = this.props;
    const { tickFormulaId, validationError } = this.state;
    const textStringMaxLength = 100;

    return (
      <Dialog
        icon='add'
        isOpen={open}
        onClose={this.handleClose}
        title={this.loc.AddTickFormulaPopupTitle}
      >
        <LykkeForm loading={loading}>
          <div className='bp5-dialog-body'>
            <LabelWithTextInput
              autoFocus={true}
              label={this.loc.ID}
              value={tickFormulaId}
              maxLength={textStringMaxLength}
              onBlur={this.validate}
              onChange={e => this.handleChange(e.target.value)}
              error={validationError}
            />
          </div>
          <div className='bp5-dialog-footer'>
            <div className='bp5-dialog-footer-actions'>
              <Button
                className='bp5-intent-none'
                disabled={loading}
                onClick={this.handleClose}
              >
                {this.loc.Cancel}
              </Button>
              <Button
                type='submit'
                intent={Intent.PRIMARY}
                disabled={loading}
                onClick={this.handleSubmit}
              >
                {this.loc.Save}
              </Button>
            </div>
          </div>
        </LykkeForm>
      </Dialog>
    );
  }
}