import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { Button, Intent, Classes, Callout } from '@blueprintjs/core';
import { LykkeForm, LabelWithTextInput } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject } from 'mobx-react';
import { every } from 'lodash';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .bp5-form-group:last-child {
    margin-bottom: 0;
  }

  .bp5-callout {
    margin-bottom: 20px;

    .bp5-icon {
      width: 40px;
      height: 40px;
    }
  }
`;

interface Props extends ILocalizedProps {
  onCancel: () => void;
  onSubmit: (mdsCode: string) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  mdsCode: string;
  fieldErrors: {
    mdsCode: string;
  };
}

@inject('i18n')
export class RenameUnderlyingForm extends React.Component<Props, State> {
  public state: State = {
    mdsCode: '',
    fieldErrors: {
      mdsCode: ''
    },
  };

  constructor(props: Props) {
    super(props);
  }

  private handleMdsCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ mdsCode: e.target.value });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.mdsCode);
    }
  }

  private validateMdsCode = () => {
    const t = this.props.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.mdsCode;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, mdsCode: t.MdsCodeFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, mdsCode: ''}
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateMdsCode()
    ]);
  }

  private getServerErrorMessages(field: string) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const t = this.props.i18n.UnderlyingsView;

    return (
      <LykkeForm>
        <StyledDiv className={Classes.DIALOG_BODY}>
          <Callout icon={IconNames.WARNING_SIGN} intent={Intent.WARNING}>{t.RenameWarningText}</Callout>
          <LabelWithTextInput
            autoFocus={true}
            label={t.MdsCodeLabel}
            value={this.state.mdsCode}
            maxLength={100}
            onChange={this.handleMdsCodeChange}
            onBlur={this.validateMdsCode}
            error={this.formatErrorMessages([this.state.fieldErrors.mdsCode, ...this.getServerErrorMessages('newMdsCode')])}
          />
        </StyledDiv>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={this.props.loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={this.props.loading}
              loading={this.props.loading}
              intent={Intent.SUCCESS}
            >
              {t.RenameOkButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}