import React, { Component } from 'react';
import moment from 'moment';
import * as Donut from '@client/donut/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext, PaginationDescriptor, StyledLykkeGridFilterWrapper } from '@elements/lykke-html/LykkeGrid';
import { LocalizationService } from '@services/LocalizationService';
import { DateTimeFormatter } from '@elements/date-formatter/DateTimeFormatter';
import { momentFromDto, toMoment } from '@scripts/utils/date-util';
import { LykkeDateInput } from '@elements/lykke-html/LykkeDateInput';
import { makeDateRangeFilterValue } from '@elements/lykke-html/LykkeGridData';
import { DateFormatter } from '@services/ui-formatters/DateFormatter';
import { NumericFormatter } from '@elements/number-formatter/NumericFormatter';
import { Button, Checkbox, Classes, MenuItem } from '@blueprintjs/core';
import { OrderTypeList } from '@client/donut/Dto/OrderBlotter/OrderTypeContract.enum';
import { OriginatorTypeList } from '@client/donut/Dto/OrderBlotter/OriginatorTypeContract.enum';
import { OrderStatusList } from '@client/donut/Dto/OrderBlotter/OrderStatusContract.enum';
import { OrderContractExtended } from '../OrderBlotterStore';
import { orderIdWithPrefix } from '@scripts/utils/id-prefix-util';
import { Suggest } from '@blueprintjs/select';
import styled from 'styled-components';
import { decimalCount } from '@scripts/utils/number-util';

export const StyledSuggestedListFilterWrapper = styled(StyledLykkeGridFilterWrapper)`
  width: 200px;
`;

interface Props {
  sb: jc.Sandbox;
  data: Donut.OrderBlotter.OrderContract[];
  loading: boolean;
  filters: Array<FilterDescriptor<Donut.OrderBlotter.OrderContract>>;
  sort: Array<SortDescriptor<Donut.OrderBlotter.OrderContract>>;
  onStateChange?(changeContext: LykkeGridContext<Donut.OrderBlotter.OrderContract>): void;
  onExportExcel?: (func: (fileName: string, rows: Donut.OrderBlotter.OrderContract[]) => void) => void;
  pagination: PaginationDescriptor;
  toolbar?: React.ReactNode;

  createdBySuggestedItems: string[];
  createdBySelectedItem: string;
  searchingCreatedBySuggestedItems: boolean;
  onCreatedBySuggestedItemsSearch(query: string): void;
  onCreatedByItemSelect(item: string): void;
  onCreatedBySelectedItemClear(): void;
  onCreatedBySuggestedItemsClose(): void;

  instrumentSuggestedItems: Donut.Assets.AssetSuggested[];
  instrumentSelectedItem: Donut.Assets.AssetSuggested;
  searchingInstrumentSuggestedItems: boolean;
  onInstrumentSuggestedItemsSearch(query: string): void;
  onInstrumentItemSelect(item: Donut.Assets.AssetSuggested): void;
  onInstrumentSelectedItemClear(): void;
  onInstrumentSuggestedItemsClose(): void;

  accountSuggestedItems: Donut.Accounts.AccountSuggested[];
  accountSelectedItem: Donut.Accounts.AccountSuggested;
  searchingAccountSuggestedItems: boolean;
  onAccountSuggestedItemsSearch(query: string): void;
  onAccountItemSelect(item: Donut.Accounts.AccountSuggested): void;
  onAccountSelectedItemClear(): void;
  onAccountSuggestedItemsClose(): void;
}

const CreatedBySuggest = Suggest.ofType<string>();
const InstrumentSuggest = Suggest.ofType<Donut.Assets.AssetSuggested>();
const AcctountSuggest = Suggest.ofType<Donut.Accounts.AccountSuggested>();

export class OrderBlotterTable extends Component<Props, {}> {
  private readonly loc: LocalizationService;
  private readonly dateFormatter: DateFormatter;

  private get dateFormat() {
    return this.dateFormatter.getMomentFormat('Date');
  }

  constructor(props: Props) {
    super(props);
    this.loc = props.sb.getService('localization');
    this.dateFormatter = props.sb.getService('date-formatter');
  }

  private getQuotePrecision(item: Donut.OrderBlotter.OrderContract) {
    const extended = item as OrderContractExtended;
    return extended.quote?.precision;
  }

  private get accountColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'accountId',
      name: t.AccountColumn,
      filterable: true,
      getCellContent: item => item.accountName
        ? `${item.accountName} (${item.accountId})`
        : item.accountId,
      getFilterContent: this.renderAccountFilter
    };
  }

  private renderAccountFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    const accountSuggestedRenderer = (item: Donut.Accounts.AccountSuggested) => item.name ? `${item.name} (${item.id})` : item.id;
    return <StyledSuggestedListFilterWrapper>
      <AcctountSuggest
        items={this.props.accountSuggestedItems}
        itemRenderer={(item, itemProps) => <MenuItem
          active={itemProps.modifiers.active}
          disabled={itemProps.modifiers.disabled}
          key={item.id}
          onClick={itemProps.handleClick}
          text={accountSuggestedRenderer(item)}
        />}
        noResults={<MenuItem disabled={true} text={t.SuggestFilterNoResults} />}
        inputValueRenderer={accountSuggestedRenderer}
        selectedItem={this.props.accountSelectedItem}
        onItemSelect={(item, event) => {
          this.props.onAccountItemSelect(item);
          updateFilter({
            ...filter,
            value: item.id
          });
        }}
        onQueryChange={(query, event) => this.props.onAccountSuggestedItemsSearch(query)}
        popoverProps={{
          minimal: true,
          onClose: event => this.props.onAccountSuggestedItemsClose()
        }}
        inputProps={{
          placeholder: t.SuggestFilterPlaceholder,
          rightElement: <Button minimal icon='cross' onClick={event => {
            this.props.onAccountSelectedItemClear();
            updateFilter({
              ...filter,
              value: ''
            });
          }} />
        }}
        resetOnClose={true}
        fill={true}
      />
    </StyledSuggestedListFilterWrapper>;
  }

  private get createdByColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'createdBy',
      name: t.CreatedByColumn,
      filterable: true,
      getCellContent: item => item.originatorType === 'OnBehalf' && item.createdBy,
      getFilterContent: this.renderCreatedByFilter
    };
  }

  private renderCreatedByFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    return <StyledSuggestedListFilterWrapper>
      <CreatedBySuggest
        items={this.props.createdBySuggestedItems}
        itemRenderer={(item, itemProps) => <MenuItem
          active={itemProps.modifiers.active}
          disabled={itemProps.modifiers.disabled}
          key={item}
          onClick={itemProps.handleClick}
          text={item}
        />}
        noResults={<MenuItem disabled={true} text={t.SuggestFilterNoResults} />}
        inputValueRenderer={item => item}
        selectedItem={this.props.createdBySelectedItem}
        onItemSelect={(item, event) => {
          this.props.onCreatedByItemSelect(item);
          updateFilter({
            ...filter,
            value: item
          });
        }}
        onQueryChange={(query, event) => this.props.onCreatedBySuggestedItemsSearch(query)}
        popoverProps={{
          minimal: true,
          onClose: event => this.props.onCreatedBySuggestedItemsClose()
        }}
        inputProps={{
          placeholder: t.SuggestFilterPlaceholder,
          rightElement: <Button minimal icon='cross' onClick={event => {
            this.props.onCreatedBySelectedItemClear();
            updateFilter({
              ...filter,
              value: ''
            });
          }} />
        }}
        resetOnClose={true}
        fill={true}
      />
    </StyledSuggestedListFilterWrapper>;
  }

  private get instrumentColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'instrument',
      name: t.InstrumentColumn,
      filterable: true,
      getFilterContent: this.renderInstrumentFilter
    };
  }

  private renderInstrumentFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    return <StyledSuggestedListFilterWrapper>
      <InstrumentSuggest
        items={this.props.instrumentSuggestedItems}
        itemRenderer={(item, itemProps) => <MenuItem
          active={itemProps.modifiers.active}
          disabled={itemProps.modifiers.disabled}
          key={item.id}
          onClick={itemProps.handleClick}
          text={item.name}
        />}
        noResults={<MenuItem disabled={true} text={t.SuggestFilterNoResults} />}
        inputValueRenderer={item => item.name}
        selectedItem={this.props.instrumentSelectedItem}
        onItemSelect={(item, event) => {
          this.props.onInstrumentItemSelect(item);
          updateFilter({
            ...filter,
            value: item.name
          });
        }}
        onQueryChange={(query, event) => this.props.onInstrumentSuggestedItemsSearch(query)}
        popoverProps={{
          minimal: true,
          onClose: event => this.props.onInstrumentSuggestedItemsClose()
        }}
        inputProps={{
          placeholder: t.SuggestFilterPlaceholder,
          rightElement: <Button minimal icon='cross' onClick={event => {
            this.props.onInstrumentSelectedItemClear();
            updateFilter({
              ...filter,
              value: ''
            });
          }} />
        }}
        resetOnClose={true}
        fill={true}
      />
    </StyledSuggestedListFilterWrapper>;
  }

  private get quantityColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'quantity',
      name: t.QuantityColumn,
      sortable: true,
      getCellContent: item => <NumericFormatter value={item.quantity} precision={0} />
    };
  }

  private get contractSizeColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'contractSize',
      name: t.ContractSizeColumn,
      getCellContent: item => <NumericFormatter value={item.contractSize} precision={0} />
    };
  }

  private get orderTypeColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'orderType',
      name: t.OrderTypeColumn,
      filterable: true,
      getCellContent: item => t.OrderTypes[item.orderType],
      getFilterContent: this.renderOrderTypeFilter
    };
  }

  private renderOrderTypeFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    return <StyledLykkeGridFilterWrapper>
      {OrderTypeList.map(x =>
        <Checkbox
          key={x}
          checked={filter.value?.includes(x)}
          label={t.OrderTypes[x]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            let value = filter.value;
            if (event.currentTarget.checked) {
              value?.push(x);
            } else {
              value = value?.filter(i => i !== x);
            }
            updateFilter({ ...filter, value });
          }}
        />
      )}
    </StyledLykkeGridFilterWrapper>;
  }

  private get orderStatusColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'orderStatus',
      name: t.OrderStatusColumn,
      filterable: true,
      getCellContent: item => t.OrderStatuses[item.orderStatus],
      getFilterContent: this.renderOrderStatusFilter
    };
  }

  private renderOrderStatusFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    return <StyledLykkeGridFilterWrapper>
      {OrderStatusList.map(x =>
        <Checkbox
          key={x}
          checked={filter.value?.includes(x)}
          label={t.OrderStatuses[x]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            let value = filter.value;
            if (event.currentTarget.checked) {
              value?.push(x);
            } else {
              value = value?.filter(i => i !== x);
            }
            updateFilter({ ...filter, value });
          }}
        />
      )}
    </StyledLykkeGridFilterWrapper>;
  }

  private get limitStopPriceColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'limitStopPrice',
      name: t.LimitStopPriceColumn,
      getCellContent: item => <NumericFormatter value={item.limitStopPrice} precision={this.getQuotePrecision(item)} />,
    };
  }

  private get takeProfitPriceColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'takeProfitPrice',
      name: t.TakeProfitPriceColumn,
      getCellContent: item => <NumericFormatter value={item.takeProfitPrice} precision={this.getQuotePrecision(item)} />,
    };
  }

  private get stopLossPriceColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'stopLossPrice',
      name: t.StopLossPriceColumn,
      getCellContent: item => <NumericFormatter value={item.stopLossPrice} precision={this.getQuotePrecision(item)} />,
    };
  }

  private get priceColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'price',
      name: t.PriceColumn,
      sortable: true,
      getCellContent: item => <NumericFormatter value={item.price} precision={this.getQuotePrecision(item)} />,
    };
  }

  private get notionalColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'notional',
      name: t.NotionalColumn,
      getCellContent: item => <NumericFormatter value={item.notional} precision={2} />,
    };
  }

  private get notionalEurColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'notionalEur',
      name: t.NotionalEurColumn,
      getCellContent: item => <NumericFormatter value={item.notionalEur} precision={2} />,
    };
  }

  private get exchangeRateColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'exchangeRate',
      name: t.ExchangeRateColumn,
      sortable: true,
      getCellContent: item => <NumericFormatter value={item.exchangeRate} precision={decimalCount(item.exchangeRate)} />,
    };
  }

  private get directionColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'direction',
      name: t.DirectionColumn,
      getCellContent: item => t.OrderDirections[item.direction],
    };
  }

  private get originatorTypeColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'originatorType',
      name: t.OriginatorTypeColumn,
      filterable: true,
      getCellContent: item => t.OriginatorTypes[item.originatorType],
      getFilterContent: this.renderOriginatorTypeFilter
    };
  }

  private renderOriginatorTypeFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    return <StyledLykkeGridFilterWrapper>
      {OriginatorTypeList.map(x =>
        <Checkbox
          key={x}
          checked={filter.value?.includes(x)}
          label={t.OriginatorTypes[x]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            let value = filter.value;
            if (event.currentTarget.checked) {
              value?.push(x);
            } else {
              value = value?.filter(i => i !== x);
            }
            updateFilter({ ...filter, value });
          }}
        />
      )}
    </StyledLykkeGridFilterWrapper>;
  }

  private get orderIdColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'orderId',
      name: t.OrderIdColumn,
      getCellContent: item => orderIdWithPrefix(item.orderId),
    };
  }

  private get createdOnColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'createdOn',
      name: t.CreatedOnColumn,
      getCellContent: item => <DateTimeFormatter dateTime={momentFromDto(item.createdOn)} format='DateAndTime' />,
      sortable: true,
      filterable: true,
      getFilterContent: this.renderCreatedOnFilter
    };
  }

  private renderCreatedOnFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    const filterArray = filter.value as moment.Moment[];
    return <StyledLykkeGridFilterWrapper>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateFromPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[0]?.toISOString() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(selectedDate ? toMoment(selectedDate) : null, filterArray[1])
          });
        }}
      />
      <br/>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateToPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[1]?.toISOString() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(filterArray[0], selectedDate ? toMoment(selectedDate) : null)
          });
        }}
      />
    </StyledLykkeGridFilterWrapper>;
  }

  private get modifiedOnColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'modifiedOn',
      name: t.ModifiedOnColumn,
      getCellContent: item => <DateTimeFormatter dateTime={momentFromDto(item.modifiedOn)} format='DateAndTime' />,
      sortable: true,
      filterable: true,
      getFilterContent: this.renderModifiedOnFilter
    };
  }

  private renderModifiedOnFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    const filterArray = filter.value as moment.Moment[];
    return <StyledLykkeGridFilterWrapper>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateFromPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[0]?.toISOString() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(selectedDate ? toMoment(selectedDate) : null, filterArray[1])
          });
        }}
      />
      <br/>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateToPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[1]?.toISOString() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(filterArray[0], selectedDate ? toMoment(selectedDate) : null)
          });
        }}
      />
    </StyledLykkeGridFilterWrapper>;
  }

  private get validityColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'validity',
      name: t.ValidityColumn,
      getCellContent: item => <DateTimeFormatter dateTime={momentFromDto(item.validity)} format='DateAndTime' />,
      sortable: true,
      getFilterContent: this.renderValidityFilter
    };
  }

  private renderValidityFilter = (filter: FilterDescriptor<Donut.OrderBlotter.OrderContract>, updateFilter: (updatedFilter: FilterDescriptor<Donut.OrderBlotter.OrderContract>) => void) => {
    const t = this.loc.i18n.OrderBlotterView;
    const filterArray = filter.value as moment.Moment[];
    return <StyledLykkeGridFilterWrapper>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateFromPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[0]?.toISOString() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(selectedDate ? toMoment(selectedDate) : null, filterArray[1])
          });
        }}
      />
      <br/>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateToPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[1]?.toISOString() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(filterArray[0], selectedDate ? toMoment(selectedDate) : null)
          });
        }}
      />
    </StyledLykkeGridFilterWrapper>;
  }

  private get orderCommentColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'orderComment',
      name: t.OrderCommentColumn
    };
  }

  private get commissionColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'commission',
      name: t.CommissionColumn,
      getCellContent: item => <NumericFormatter value={item.commission} precision={2} />,
    };
  }

  private get onBehalfFeeColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'onBehalfFee',
      name: t.OnBehalfFeeColumn,
      getCellContent: item => <NumericFormatter value={item.onBehalfFee} precision={2} />,
    };
  }

  private get spreadColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'spread',
      name: t.SpreadColumn,
      getCellContent: item => <NumericFormatter value={item.spread} precision={2} />,
    };
  }

  private get forcedOpenColumn(): LykkeGridColumn<Donut.OrderBlotter.OrderContract> {
    const t = this.loc.i18n.OrderBlotterView;
    return {
      field: 'forcedOpen',
      name: t.ForcedOpenColumn,
      getExcelContent: item => item.forcedOpen ? '+' : '-',
      getCellContent: item => <input type='checkbox' className={Classes.CHECKBOX} checked={item.forcedOpen} disabled={true} />,
    };
  }

  private get columns() {
    const result = [
      this.accountColumn,
      this.createdByColumn,
      this.instrumentColumn,
      this.quantityColumn,
      this.contractSizeColumn,
      this.orderTypeColumn,
      this.orderStatusColumn,
      this.limitStopPriceColumn,
      this.takeProfitPriceColumn,
      this.stopLossPriceColumn,
      this.priceColumn,
      this.notionalColumn,
      this.notionalEurColumn,
      this.exchangeRateColumn,
      this.directionColumn,
      this.originatorTypeColumn,
      this.orderIdColumn,
      this.createdOnColumn,
      this.modifiedOnColumn,
      this.validityColumn,
      this.orderCommentColumn,
      this.commissionColumn,
      this.onBehalfFeeColumn,
      this.spreadColumn,
      this.forcedOpenColumn
    ];
    return result;
  }

  public render() {
    return (
      <LykkeGrid
        sandbox={this.props.sb}
        uniqueRowKey='orderId'
        columns={this.columns}
        filters={this.props.filters}
        toolbar={this.props.toolbar}
        columnsSelectable
        sort={this.props.sort}
        onStateChange={this.props.onStateChange}
        onExportExcel={this.props.onExportExcel}
        loading={this.props.loading}
        rows={this.props.data}
        pageable={true}
        pagination={this.props.pagination}
        paginationSettings={{ position: ['bottomRight'], showSizeChanger: true }}
      />
    );
  }
}