import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { inject } from 'mobx-react';
import { omit, every } from 'lodash';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';

interface Props extends ILocalizedProps {
  itemToEdit?: Backoffice.Currencies.CurrencyContract; // if value is passed, then the user is editing a currency item
  onCancel: () => void;
  onSubmit: (currencyData: Backoffice.Currencies.CurrencyContract) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.Currencies.CurrencyContract;
  fieldErrors: {[P in keyof Backoffice.Currencies.CurrencyContract]?: string};
}

const getEmptyFormData = () => ({
  id: '',
  interestRateMdsCode: '',
  accuracy: null
} as Backoffice.Currencies.CurrencyContract);

@inject('i18n')
export class AddEditCurrencyForm extends React.Component<Props, State> {
  public state: State = {
    formData: { ...(this.props.itemToEdit ?? getEmptyFormData()) },
    fieldErrors: {},
  };

  constructor(props) {
    super(props);
  }

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    const newFormData = {
      ...this.state.formData,
      [name]: value
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateId = () => {
    const value = this.state.formData.id;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, id: this.props.i18n.BrokerSettingsView.InterestRatesTab.CurrencyFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'id')
      }));
      return true;
    }
  }

  private validateInterestRateMdsCode = () => {
    const value = this.state.formData.interestRateMdsCode;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, interestRateMdsCode: this.props.i18n.BrokerSettingsView.InterestRatesTab.MdsCodeFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'interestRateMdsCode')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateId(),
      this.validateInterestRateMdsCode()
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.Currencies.CurrencyContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.Currencies.CurrencyContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { itemToEdit = null, loading } = this.props;
    const t = this.props.i18n.BrokerSettingsView.InterestRatesTab;

    return (
      <LykkeForm>
        <div className='bp5-dialog-body'>
          <LabelWithTextInput
            autoFocus={true}
            label={t.LabelCurrency}
            value={this.state.formData.id}
            onChange={this.handleInputChange}
            onBlur={this.validateId}
            name={'id'}
            placeholder={t.LabelCurrency}
            disabled={!!itemToEdit}
            error={this.formatErrorMessages([this.getClientErrorMessage('id'), ...this.getServerErrorMessages('id')])}
          />
          <LabelWithTextInput
            name={'interestRateMdsCode'}
            label={t.LabelMdsCode}
            placeholder={t.LabelMdsCode}
            value={this.state.formData.interestRateMdsCode}
            onChange={this.handleInputChange}
            onBlur={this.validateInterestRateMdsCode}
            error={this.formatErrorMessages([this.getClientErrorMessage('interestRateMdsCode'), ...this.getServerErrorMessages('interestRateMdsCode')])}
          />
        </div>
        <div className='bp5-dialog-footer'>
          <div className='bp5-dialog-footer-actions'>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {itemToEdit ? t.UpdateButtonLabel : t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}