import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { LykkeForm } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject, observer } from 'mobx-react';
import { OpenPosition } from '@models/OpenPosition';
import { Classes } from '@blueprintjs/core/lib/cjs/common';
import classNames from 'classnames';
import { DefaultNumericInput } from '@elements/default-numeric-input/DefaultNumericInput';
import styled from 'styled-components';
import { ChartStore } from '@scripts/features/charts/ChartStore';
import { validateActiveOrderPrice } from '@scripts/helpers/price-validator-helper';
import { OrderType } from '@models/enums/OrderType';
import { Side } from '@models/Side';

const StyledCancelLabel = styled.label`
  flex-direction: row !important;
  align-items: center;

  input {
    width: initial !important;
    margin-right: 3px;
  }
`;

const StyledSelect = styled.select`
  position: relative;
  top: 2px;
  margin-bottom: 5px;
  border: none;
  background: transparent;

  option {
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.mainTextColor};
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLykkeForm = styled(LykkeForm)`
  .buttons-right {
    right: 21px;
  }
`;

const StyledDefaultNumericInput = styled(DefaultNumericInput)`
  .bp5-button-group {
    margin-top: 0 !important;
  }
`;

interface Props extends ILocalizedProps {
  openPosition: OpenPosition;
  store: ChartStore;
  onSubmit();
}

interface State {
  loading: boolean;
  takeProfit: number;
  stopLoss: number;
  isTrailingStop: boolean;
  cancelTakeProfitChecked: boolean;
  cancelStopLossChecked: boolean;
}

@inject('i18n')
@observer
export class EditPositionForm extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    takeProfit: this.props.openPosition.currentTakeProfit.Value,
    stopLoss: this.props.openPosition.currentStopLoss.Value,
    isTrailingStop: this.props.openPosition.actualDistance !== null,
    cancelTakeProfitChecked: false,
    cancelStopLossChecked: false,
  };

  private get isStopLossDirty() {
    const position = this.props.openPosition;

    return this.state.stopLoss !== position.currentStopLoss.Value
      || this.state.isTrailingStop !== (position.actualDistance !== null)
      || this.state.cancelStopLossChecked;
  }

  private get isTakeProfitDirty() {
    const position = this.props.openPosition;

    return this.state.takeProfit !== position.currentTakeProfit.Value
      || this.state.cancelTakeProfitChecked;
  }

  private get isDirty() {
    return this.isStopLossDirty || this.isTakeProfitDirty;
  }

  public render() {
    const position = this.props.openPosition;
    const { i18n } = this.props;
    const t = this.props.i18n.Charts.ModifyPosition;
    const side = Side.fromPosition(position.direction);

    const validateTpPriceError = validateActiveOrderPrice(
      this.state.takeProfit,
      OrderType.TakeProfit,
      side,
      position.quote);
    const isTpValidPrice = (this.state.takeProfit === null && position.currentTakeProfit.Value === null)
      || validateTpPriceError === 'None';

    const validateSlOrTsPriceError = validateActiveOrderPrice(
      this.state.stopLoss,
      this.state.isTrailingStop ? OrderType.TrailingStop : OrderType.StopLoss,
      side,
      position.quote);
    const isSlOrTsValidPrice = (this.state.stopLoss === null && position.currentStopLoss.Value === null)
      || validateSlOrTsPriceError === 'None';

    return (
      <StyledLykkeForm>
        <StyledDiv>
          <div className={Classes.FORM_GROUP}>
            <label className={classNames(Classes.LABEL, 'lykke-input', 'lykke-input-no-padding')}>
              <span className='title'>{t.TakeProfitLabel}</span>
              <div className={classNames(Classes.INPUT_GROUP, {[Classes.INTENT_DANGER]: !isTpValidPrice})}>
                <StyledDefaultNumericInput
                  style={{ textDecoration: this.state.cancelTakeProfitChecked ? 'line-through' : null }}
                  value={this.state.takeProfit}
                  precision={position.takeProfit.quote.precision}
                  onValueChange={this.handleTakeProfitChange}
                  stepSize={position.takeProfit.stepSize}
                  minorStepSize={position.takeProfit.stepSize}
                  majorStepSize={position.takeProfit.stepSize}
                  onClick={this.handleTakeProfitClick}
                  minimumValue={0}
                  showZero={true}
                  hideResetValue={true}
                  disabled={this.state.cancelTakeProfitChecked}
                  errorHint={isTpValidPrice ? undefined : i18n.PriceValidationErrors[validateTpPriceError]}
                />
              </div>
            </label>
            <StyledCancelLabel>
              <input
                type='checkbox'
                className={Classes.CHECKBOX}
                checked={this.state.cancelTakeProfitChecked}
                disabled={!position.currentTakeProfit.Value}
                onChange={this.handleCancelTakeProfitChange}
              />
              <span>{t.CancelOrderCheckboxLabel}</span>
            </StyledCancelLabel>
          </div>
          <div className={Classes.FORM_GROUP}>
            <label className={classNames(Classes.LABEL, 'lykke-input', 'lykke-input-no-padding')}>
              <StyledSelect value={this.state.isTrailingStop.toString()} onChange={e =>
                this.setState({ isTrailingStop: e.target.value === true.toString() })
              }>
                <option value={true.toString()}>{t.TrailingStopLabel}</option>
                <option value={false.toString()}>{t.StopLossLabel}</option>
              </StyledSelect>
              <div className={classNames(Classes.INPUT_GROUP, {[Classes.INTENT_DANGER]: !isSlOrTsValidPrice})}>
                <StyledDefaultNumericInput
                  style={{ textDecoration: this.state.cancelStopLossChecked ? 'line-through' : null }}
                  value={this.state.stopLoss}
                  precision={position.stopLoss.quote.precision}
                  onValueChange={this.handleStopLossChange}
                  stepSize={position.stopLoss.stepSize}
                  minorStepSize={position.stopLoss.stepSize}
                  majorStepSize={position.stopLoss.stepSize}
                  onClick={this.handleStopLossClick}
                  minimumValue={0}
                  showZero={true}
                  hideResetValue={true}
                  disabled={this.state.cancelStopLossChecked}
                  errorHint={isSlOrTsValidPrice ? undefined : i18n.PriceValidationErrors[validateSlOrTsPriceError]}
                />
              </div>
            </label>
            <StyledCancelLabel>
              <input
                type='checkbox'
                className={Classes.CHECKBOX}
                checked={this.state.cancelStopLossChecked}
                disabled={!position.currentStopLoss.Value}
                onChange={this.handleCancelStopLossChange}
              />
              <span>{t.CancelOrderCheckboxLabel}</span>
            </StyledCancelLabel>
          </div>
        </StyledDiv>
        <div className='update-price-control-buttons'>
          <Button
            type='submit'
            className={Classes.FILL}
            onClick={this.handleSubmit}
            disabled={!isTpValidPrice || !isSlOrTsValidPrice || !this.isDirty || this.state.loading}
            loading={this.state.loading}
            intent={Intent.PRIMARY}
          >
            {t.SendButtonLabel}
          </Button>
        </div>
      </StyledLykkeForm>
    );
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { store, openPosition } = this.props;
    const t = this.props.i18n.Charts.ModifyPosition;

    e.preventDefault();

    const confirmed = await store.alert.confirm({
      intent: 'primary',
      message: [t.ConfirmModifyText],
      cancelText: t.ConfirmNoButtonLabel,
      confirmText: t.ConfirmYesButtonLabel,
    });

    if (!confirmed) {
      return;
    }

    this.setState({ loading: true });
    const promises = [];
    if (this.isStopLossDirty) {
      promises.push(
        this.state.cancelStopLossChecked
          ? store.cancelOrder(openPosition.stopLossOrderId)
          : store.updateStopLossForPosition(openPosition.id, this.state.stopLoss, this.state.isTrailingStop)
      );
    }
    if (this.isTakeProfitDirty) {
      promises.push(
        this.state.cancelTakeProfitChecked
          ? store.cancelOrder(openPosition.takeProfitOrderId)
          : store.updateTakeProfitForPosition(openPosition.id, this.state.takeProfit)
      );
    }
    await Promise.all(promises);
    this.setState({ loading: false });
    this.props.onSubmit();
  }

  private handleTakeProfitChange = (valueAsNumber: number, valueAsString: string) => {
    this.setState({ takeProfit: !!valueAsString ? valueAsNumber : null });
  }

  private handleStopLossChange = (valueAsNumber: number, valueAsString: string) => {
    this.setState({ stopLoss: !!valueAsString ? valueAsNumber : null });
  }

  private handleCancelStopLossChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const position = this.props.openPosition;
    const cancelStopLossChecked = e.target.checked;

    if (cancelStopLossChecked) {
      this.setState({
        stopLoss: position.currentStopLoss.Value,
        isTrailingStop: position.actualDistance !== null
      });
    }
    this.setState({ cancelStopLossChecked });
  }

  private handleCancelTakeProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const position = this.props.openPosition;
    const cancelTakeProfitChecked = e.target.checked;

    if (cancelTakeProfitChecked) {
      this.setState({ takeProfit: position.currentTakeProfit.Value });
    }
    this.setState({ cancelTakeProfitChecked });
  }

  private handleStopLossClick = () => {
    const position = this.props.openPosition;

    if (!this.state.stopLoss) {
      const newValue = position.stopLoss.getDefaultPrice().Value;
      this.setState({ stopLoss: newValue });
    }
  }

  private handleTakeProfitClick = () => {
    const position = this.props.openPosition;

    if (!this.state.takeProfit) {
      const newValue = position.takeProfit.getDefaultPrice().Value;
      this.setState({ takeProfit: newValue });
    }
  }
}