import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { LykkeForm } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core/lib/cjs/common';
import classNames from 'classnames';
import { DefaultNumericInput } from '@elements/default-numeric-input/DefaultNumericInput';
import styled from 'styled-components';
import { ChartStore } from '@scripts/features/charts/ChartStore';
import { OpenOrder } from '@models/OpenOrder';
import { OrderType } from '@models/enums/OrderType';
import { isNil } from 'lodash';
import { getDefaultAssetAmount, getOrderNonTakeProfitFactor } from '@scripts/helpers/assets-helpers';
import { OrderStatus } from '@models/enums/OrderStatus';
import CustomNumber from '@models/customNumber';
import { validateActiveOrderPrice, validateInactiveOrderPrice } from '@scripts/helpers/price-validator-helper';

const StyledCancelLabel = styled.label`
  flex-direction: row !important;
  align-items: center;

  input {
    width: initial !important;
    margin-right: 3px;
  }
`;

const StyledSelect = styled.select`
  position: relative;
  top: 2px;
  margin-bottom: 5px;
  border: none;
  background: transparent;

  option {
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.mainTextColor};
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLykkeForm = styled(LykkeForm)`
  .buttons-right {
    right: 21px;
  }
`;

const StyledDefaultNumericInput = styled(DefaultNumericInput)`
  .bp5-button-group {
    margin-top: 0 !important;
  }
`;

interface Props extends ILocalizedProps {
  openOrder: OpenOrder;
  store: ChartStore;
  onSubmit();
}

interface State {
  loading: boolean;
  limitStop: number;
  takeProfit: number;
  stopLoss: number;
  isTrailingStop: boolean;
  cancelTakeProfitChecked: boolean;
  cancelStopLossChecked: boolean;
}

@inject('i18n')
@observer
export class EditOrderForm extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    limitStop: this.props.openOrder.currentLimitStop.Value,
    takeProfit: this.props.openOrder.currentTakeProfit.Value,
    stopLoss: this.props.openOrder.currentStopLoss.Value,
    isTrailingStop: this.props.openOrder.hasTrailingStop,
    cancelTakeProfitChecked: false,
    cancelStopLossChecked: false,
  };

  // TODO: make reusable
  private get defaultStopLoss() {
    const order = this.props.openOrder;

    if (isNil(order.instrumentDetails.minOrderDistancePercent)) {
      return order.stopLoss.getDefaultPrice().Value;
    } else {
      const propAssetAmount = order.orderSettings ? order.orderSettings.stopLoss : null;
      const assetAmount = propAssetAmount || getDefaultAssetAmount(order.instrumentDetails.minOrderDistancePercent, this.state.limitStop);
      const newPriceDirection = order.stopLoss.side.toDirection();
      const nonTakeProfitFactor = getOrderNonTakeProfitFactor(order.stopLoss);

      return new CustomNumber(this.state.limitStop + assetAmount * newPriceDirection * nonTakeProfitFactor, order.stopLoss.precision).Value;
    }
  }

  private get defaultTakeProfit() {
    const order = this.props.openOrder;

    if (isNil(order.instrumentDetails.minOrderDistancePercent)) {
      return order.takeProfit.getDefaultPrice().Value;
    } else {
      const propAssetAmount = order.orderSettings ? order.orderSettings.takeProfit : null;
      const assetAmount = propAssetAmount || getDefaultAssetAmount(order.instrumentDetails.minOrderDistancePercent, this.state.limitStop);
      const newPriceDirection = order.takeProfit.side.toDirection();
      const nonTakeProfitFactor = getOrderNonTakeProfitFactor(order.takeProfit);

      return new CustomNumber(this.state.limitStop + assetAmount * newPriceDirection * nonTakeProfitFactor, order.takeProfit.precision).Value;
    }
  }

  private get isStopLossDirty() {
    const order = this.props.openOrder;

    return this.state.stopLoss !== order.currentStopLoss.Value
      || this.state.isTrailingStop !== order.hasTrailingStop
      || this.state.cancelStopLossChecked;
  }

  private get isTakeProfitDirty() {
    const order = this.props.openOrder;

    return this.state.takeProfit !== order.currentTakeProfit.Value
      || this.state.cancelTakeProfitChecked;
  }

  private get isLimitStopDirty() {
    const order = this.props.openOrder;

    return this.state.limitStop !== order.currentLimitStop.Value;
  }

  private get isDirty() {
    return this.isLimitStopDirty || this.isStopLossDirty || this.isTakeProfitDirty;
  }

  public render() {
    const order = this.props.openOrder;
    const { i18n } = this.props;
    const t = i18n.Charts.ModifyOrder;
    // TODO: check why it was implemented this way for OpenOrder
    const side = order.type === OrderType.Limit || order.type === OrderType.Stop
      ? order.direction
      : order.direction.invertedSide;
    const parentOrder = this.props.store.orders.find(x => x.id === order.parentOrderId);

    const validatePriceError = order.status === OrderStatus.Active
      ? validateActiveOrderPrice(this.state.limitStop, order.type, side, order.quote)
      : validateInactiveOrderPrice(
          this.state.limitStop,
          order.type,
          side,
          parentOrder?.type,
          parentOrder?.currentLimitStop.Value
        );
    const isValidPrice = validatePriceError === 'None';

    const tpPriceValidation = this.validateTpPriceIfNeeded();
    const slOrTsPriceValidation = this.validateSlOrTsPriceIfNeeded();
    return (
      <StyledLykkeForm>
        <div className={Classes.FORM_GROUP}>
          <label className={classNames(Classes.LABEL, 'lykke-input', 'lykke-input-no-padding')}>
            <span className='title'>{t.LimitStopLabel}</span>
            <div className={classNames(Classes.INPUT_GROUP, {[Classes.INTENT_DANGER]: !isValidPrice})}>
              <StyledDefaultNumericInput
                value={this.state.limitStop}
                precision={order.limitStop.quote.precision}
                onValueChange={this.handleLimitStopChange}
                stepSize={order.limitStop.stepSize}
                minorStepSize={order.limitStop.stepSize}
                majorStepSize={order.limitStop.stepSize}
                minimumValue={0}
                showZero={true}
                hideResetValue={true}
                errorHint={isValidPrice ? undefined : i18n.PriceValidationErrors[validatePriceError]}
              />
            </div>
          </label>
        </div>
        {
          (this.props.openOrder.type === OrderType.Limit || this.props.openOrder.type === OrderType.Stop) &&
          <StyledDiv>
            <div className={Classes.FORM_GROUP}>
              <label className={classNames(Classes.LABEL, 'lykke-input', 'lykke-input-no-padding')}>
                <span className='title'>{t.TakeProfitLabel}</span>
                <div className={classNames(Classes.INPUT_GROUP, {[Classes.INTENT_DANGER]: !tpPriceValidation.result})}>
                  <StyledDefaultNumericInput
                    style={{ textDecoration: this.state.cancelTakeProfitChecked ? 'line-through' : null }}
                    value={this.state.takeProfit}
                    precision={order.takeProfit.quote.precision}
                    onValueChange={this.handleTakeProfitChange}
                    stepSize={order.takeProfit.stepSize}
                    minorStepSize={order.takeProfit.stepSize}
                    majorStepSize={order.takeProfit.stepSize}
                    onClick={this.handleTakeProfitClick}
                    minimumValue={0}
                    showZero={true}
                    hideResetValue={true}
                    disabled={this.state.cancelTakeProfitChecked}
                    errorHint={tpPriceValidation.result ? undefined : i18n.PriceValidationErrors[tpPriceValidation.error]}
                  />
                </div>
              </label>
              <StyledCancelLabel>
                <input
                  type='checkbox'
                  className={Classes.CHECKBOX}
                  checked={this.state.cancelTakeProfitChecked}
                  disabled={!order.currentTakeProfit.Value}
                  onChange={this.handleCancelTakeProfitChange}
                />
                <span>{t.CancelOrderCheckboxLabel}</span>
              </StyledCancelLabel>
            </div>
            <div className={Classes.FORM_GROUP}>
              <label className={classNames(Classes.LABEL, 'lykke-input', 'lykke-input-no-padding')}>
                <StyledSelect value={this.state.isTrailingStop.toString()} onChange={e =>
                  this.setState({ isTrailingStop: e.target.value === true.toString() })
                }>
                  <option value={true.toString()}>{t.TrailingStopLabel}</option>
                  <option value={false.toString()}>{t.StopLossLabel}</option>
                </StyledSelect>
                <div className={classNames(Classes.INPUT_GROUP, {[Classes.INTENT_DANGER]: !slOrTsPriceValidation.result})}>
                  <StyledDefaultNumericInput
                    style={{ textDecoration: this.state.cancelStopLossChecked ? 'line-through' : null }}
                    value={this.state.stopLoss}
                    precision={order.stopLoss.quote.precision}
                    onValueChange={this.handleStopLossChange}
                    stepSize={order.stopLoss.stepSize}
                    minorStepSize={order.stopLoss.stepSize}
                    majorStepSize={order.stopLoss.stepSize}
                    onClick={this.handleStopLossClick}
                    minimumValue={0}
                    showZero={true}
                    hideResetValue={true}
                    disabled={this.state.cancelStopLossChecked}
                    errorHint={slOrTsPriceValidation.result ? undefined : i18n.PriceValidationErrors[slOrTsPriceValidation.error]}
                  />
                </div>
              </label>
              <StyledCancelLabel>
                <input
                  type='checkbox'
                  className={Classes.CHECKBOX}
                  checked={this.state.cancelStopLossChecked}
                  disabled={!order.currentStopLoss.Value}
                  onChange={this.handleCancelStopLossChange}
                />
                <span>{t.CancelOrderCheckboxLabel}</span>
              </StyledCancelLabel>
            </div>
          </StyledDiv>
        }
        <div className='update-price-control-buttons'>
          <Button
            type='submit'
            className={Classes.FILL}
            onClick={this.handleSubmit}
            disabled={!isValidPrice || !tpPriceValidation.result || !slOrTsPriceValidation.result || !this.isDirty || this.state.loading}
            loading={this.state.loading}
            intent={Intent.PRIMARY}
          >
            {t.SendButtonLabel}
          </Button>
        </div>
      </StyledLykkeForm>
    );
  }

  private validateTpPriceIfNeeded() {
    const order = this.props.openOrder;
    const side = order.direction;

    if ((this.props.openOrder.type !== OrderType.Limit && this.props.openOrder.type !== OrderType.Stop)) {
      return { result: true, error: 'None' };
    }

    if (this.state.takeProfit === null && order.currentTakeProfit.Value === null) {
      return { result: true, error: 'None' };
    }

    const validateTpPriceError = validateInactiveOrderPrice(
      this.state.takeProfit,
      OrderType.TakeProfit,
      side,
      order.type,
      order.currentLimitStop.Value
    );
    return { result: validateTpPriceError === 'None', error: validateTpPriceError };
  }

  private validateSlOrTsPriceIfNeeded() {
    const order = this.props.openOrder;
    const side = order.direction;

    if ((this.props.openOrder.type !== OrderType.Limit && this.props.openOrder.type !== OrderType.Stop)) {
      return { result: true, error: 'None' };
    }

    if (this.state.stopLoss === null && order.currentStopLoss.Value === null) {
      return { result: true, error: 'None' };
    }

    const validateTpPriceError = validateInactiveOrderPrice(
      this.state.stopLoss,
      this.state.isTrailingStop ? OrderType.TrailingStop : OrderType.StopLoss,
      side,
      order.type,
      order.currentLimitStop.Value
    );
    return { result: validateTpPriceError === 'None', error: validateTpPriceError };
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { store, openOrder } = this.props;
    const t = this.props.i18n.Charts.ModifyOrder;

    e.preventDefault();

    const confirmed = await store.alert.confirm({
      intent: 'primary',
      message: [t.ConfirmModifyText],
      cancelText: t.ConfirmNoButtonLabel,
      confirmText: t.ConfirmYesButtonLabel,
    });

    if (!confirmed) {
      return;
    }

    this.setState({ loading: true });
    if (this.isLimitStopDirty) {
      await store.updateLimitStopForOrder(openOrder.id, this.state.limitStop);
    }
    const promises = [];
    if (this.isStopLossDirty) {
      promises.push(
        this.state.cancelStopLossChecked
          ? store.cancelOrder(openOrder.stopLossOrderId)
          : store.updateStopLossForOrder(openOrder.id, this.state.stopLoss, this.state.isTrailingStop)
      );
    }
    if (this.isTakeProfitDirty) {
      promises.push(
        this.state.cancelTakeProfitChecked
          ? store.cancelOrder(openOrder.takeProfitOrderId)
          : store.updateTakeProfitForOrder(openOrder.id, this.state.takeProfit)
      );
    }
    await Promise.all(promises);
    this.setState({ loading: false });
    this.props.onSubmit();
  }

  private handleLimitStopChange = (valueAsNumber: number, valueAsString: string) => {
    this.setState({ limitStop: !!valueAsString ? valueAsNumber : null });
  }

  private handleTakeProfitChange = (valueAsNumber: number, valueAsString: string) => {
    this.setState({ takeProfit: !!valueAsString ? valueAsNumber : null });
  }

  private handleStopLossChange = (valueAsNumber: number, valueAsString: string) => {
    this.setState({ stopLoss: !!valueAsString ? valueAsNumber : null });
  }

  private handleCancelStopLossChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const order = this.props.openOrder;
    const cancelStopLossChecked = e.target.checked;

    if (cancelStopLossChecked) {
      this.setState({
        stopLoss: order.currentStopLoss.Value,
        isTrailingStop: order.hasTrailingStop
      });
    }
    this.setState({ cancelStopLossChecked });
  }

  private handleCancelTakeProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const order = this.props.openOrder;
    const cancelTakeProfitChecked = e.target.checked;

    if (cancelTakeProfitChecked) {
      this.setState({ takeProfit: order.currentTakeProfit.Value });
    }
    this.setState({ cancelTakeProfitChecked });
  }

  private handleStopLossClick = () => {
    if (!this.state.stopLoss) {
      this.setState({ stopLoss: this.defaultStopLoss });
    }
  }

  private handleTakeProfitClick = () => {
    if (!this.state.takeProfit) {
      this.setState({ takeProfit: this.defaultTakeProfit });
    }
  }
}