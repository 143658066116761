/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * NOTE!
 * This is custom implementation of `blueprintJS/NumericInput`
 * Which allows us to get controle of LOCALIZED values
 * Default implementation does not allow to intercept value, coming from input and change it
 * It just automatically sets everything, what does not matches, to '0'
 */
import classNames from 'classnames';
import * as React from 'react';

import {
  Classes,
  HTMLInputProps,
  IntentProps,
  Props,
  Keys,
  Position,
  removeNonHTMLProps,
  Utils,
} from '@blueprintjs/core/lib/cjs/common';
import * as Errors from '@blueprintjs/core/lib/cjs/common/errors';
import { ButtonGroup, Button, ControlGroup, InputGroup, Icon } from '@blueprintjs/core';
import { isNil } from 'lodash';
import { ILocalizedProps } from '@elements/localization';

import { AbstractPureComponent2 } from '../../common/AbstractPureComponent2';
import { isKeyboardClick } from '../../common/utils';
import {
  clampValue,
  getValueOrEmptyValue,
  isFloatingPointNumericCharacter,
  isValidNumericKeyboardEvent,
  toMaxPrecision,
  parseLocaleStringAsNumericValue,
} from './utils';
import { IDefaultNumericInputProps } from '@elements/default-numeric-input/DefaultNumericInput';
import { Tooltip2 } from '@blueprintjs/popover2';
import styled from 'styled-components';

const StyledControlGroup = styled(ControlGroup)`
  position: relative;
`;

const StyledErrorDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 99;

  > div {
    margin-left: 25%;
    margin-top: -25%;
  }

  .bp5-popover-target {
    display: flex !important;
    margin-top: 0 !important;
  }

  .bp5-icon {
    border-radius: 50%;
    background: white;
  }

  svg {
    fill: #db3737 !important;
  }
`;

export interface NumericInputProps extends IntentProps, Props, ILocalizedProps {
  errorHint?: string | JSX.Element;

  /**
   * Whether to allow only floating-point number characters in the field,
   * mimicking the native `input[type='number']`.
   * @default true
   */
  allowNumericCharactersOnly?: boolean;

  /**
   * The position of the buttons with respect to the input field.
   * @default Position.RIGHT
   */
  buttonPosition?: typeof Position.LEFT | typeof Position.RIGHT | 'none';

  /**
   * Whether the value should be clamped to `[min, max]` on blur.
   * The value will be clamped to each bound only if the bound is defined.
   * Note that native `input[type='number']` controls do *NOT* clamp on blur.
   * @default false
   */
  clampValueOnBlur?: boolean;

  /**
   * Whether the input is non-interactive.
   * @default false
   */
  disabled?: boolean;

  /** Whether the numeric input should take up the full width of its container. */
  fill?: boolean;

  /**
   * Ref handler that receives HTML `<input>` element backing this component.
   */
  inputRefFunc?: (ref: HTMLInputElement | null) => any;

  /**
   * If set to `true`, the input will display with larger styling.
   * This is equivalent to setting `Classes.LARGE` via className on the
   * parent control group and on the child input group.
   * @default false
   */
  large?: boolean;

  /**
   * The increment between successive values when <kbd>shift</kbd> is held.
   * Pass explicit `null` value to disable this interaction.
   * @default 10
   */
  majorStepSize?: number | null;

  /** The maximum value of the input. */
  max?: number;

  /** The minimum value of the input. */
  min?: number;

  /**
   * The increment between successive values when <kbd>alt</kbd> is held.
   * Pass explicit `null` value to disable this interaction.
   * @default 0.1
   */
  minorStepSize?: number | null;

  /** The placeholder text in the absence of any value. */
  placeholder?: string;

  /**
   * Element to render on left side of input.
   * For best results, use a minimal button, tag, or small spinner.
   */
  leftElement?: JSX.Element;

  /**
   * Element to render on right side of input.
   * For best results, use a minimal button, tag, or small spinner.
   */
  rightElement?: JSX.Element;

  /**
   * Whether the entire text field should be selected on focus.
   * @default false
   */
  selectAllOnFocus?: boolean;

  /**
   * Whether the entire text field should be selected on increment.
   * @default false
   */
  selectAllOnIncrement?: boolean;

  /**
   * The increment between successive values when no modifier keys are held.
   * @default 1
   */
  stepSize?: number;

  /** The value to display in the input field. */
  value?: number | string;

  minimumFractionDigits?: number;

  autoFocus?: boolean;

  /** The callback invoked when the value changes due to a button click. */
  onButtonClick?(valueAsNumber: number, valueAsString: string): void;

  /** The callback invoked when the value changes due to typing, arrow keys, or button clicks. */
  onValueChange?(valueAsNumber: number, valueAsString: string): void;
}

export interface INumericInputState {
  shouldSelectAfterUpdate: boolean;
  stepMaxPrecision: number;
  value: string;
  pendingIncrement: number;
  isFocused: boolean;

  prevMinProp?: number;
  prevMaxProp?: number;
  prevValueProp?: number | string;
}

enum IncrementDirection {
  DOWN = -1,
  UP = +1,
}

const NON_HTML_PROPS: Array<keyof NumericInputProps | keyof IDefaultNumericInputProps> = [
  'allowNumericCharactersOnly',
  'buttonPosition',
  'clampValueOnBlur',
  'className',
  'majorStepSize',
  'minorStepSize',
  'onButtonClick',
  'onValueChange',
  'selectAllOnFocus',
  'selectAllOnIncrement',
  'stepSize',
  'minimumFractionDigits',
  'showControlButtons',
  'onControlledValueChange'
];

type ButtonEventHandlers = Required<Pick<React.HTMLAttributes<Element>, 'onKeyDown' | 'onMouseDown'>>;

export class LocaleNumericInput extends AbstractPureComponent2<HTMLInputProps & NumericInputProps, INumericInputState> {
  public static displayName: string = `LocaleNumericInput`;

  public static VALUE_EMPTY: string = '';
  public static VALUE_ZERO: string = '0';
  public static DEFAULT_MINIMUM_FRACTION_DIGITS: number = 0;

  public static defaultProps: NumericInputProps = {
    allowNumericCharactersOnly: true,
    buttonPosition: Position.RIGHT,
    clampValueOnBlur: false,
    large: false,
    majorStepSize: 10,
    minorStepSize: 0.1,
    selectAllOnFocus: false,
    selectAllOnIncrement: false,
    stepSize: 1,
    value: LocaleNumericInput.VALUE_EMPTY,
    minimumFractionDigits: LocaleNumericInput.DEFAULT_MINIMUM_FRACTION_DIGITS,
  };

  public static getDerivedStateFromProps(props: NumericInputProps, state: INumericInputState): Partial<INumericInputState> {
    let nextState: Partial<INumericInputState> = { prevMinProp: props.min, prevMaxProp: props.max, prevValueProp: props.value };

    const didMinChange = props.min !== state.prevMinProp;
    const didMaxChange = props.max !== state.prevMaxProp;
    const didBoundsChange = didMinChange || didMaxChange;
    const didValuePropChange = props.value !== state.prevValueProp;

    let activeValue = didValuePropChange ? props.value : state.value;

    if (didValuePropChange && LocaleNumericInput.isValueUnset(state.prevValueProp) && state.pendingIncrement) {
      const activeValueAsNumber = parseLocaleStringAsNumericValue(activeValue.toString());
      activeValue = activeValueAsNumber + state.pendingIncrement;
      /**
       * If pending increment had been used -> we don't need it anymore, so resetting it to zero value.
       */
      nextState = {
        ...nextState,
        pendingIncrement: 0,
      };
    }

    const minimumFractionDigits = state.isFocused ? LocaleNumericInput.DEFAULT_MINIMUM_FRACTION_DIGITS : props.minimumFractionDigits;
    const maximumFractionDigits = LocaleNumericInput.getStepMaxPrecision(props, minimumFractionDigits);

    const value = getValueOrEmptyValue(
      activeValue,
      props.loc.language,
      LocaleNumericInput.getLocaleOptions(
        minimumFractionDigits,
        maximumFractionDigits,
      )
    );

    const sanitizedValue =
      value !== LocaleNumericInput.VALUE_EMPTY
        ? LocaleNumericInput.getSanitizedValue(
          value,
          maximumFractionDigits,
          props.min,
          props.max,
          props.loc.language,
          0,
          minimumFractionDigits,
        )
        : LocaleNumericInput.VALUE_EMPTY;

    // if a new min and max were provided that cause the existing value to fall
    // outside of the new bounds, then clamp the value to the new valid range.
    if (didBoundsChange && sanitizedValue !== state.value) {
      return { ...nextState, stepMaxPrecision: maximumFractionDigits, value: sanitizedValue };
    } else {
      return { ...nextState, stepMaxPrecision: maximumFractionDigits, value };
    }
  }

  private static CONTINUOUS_CHANGE_DELAY = 300;
  private static CONTINUOUS_CHANGE_INTERVAL = 100;

  // Value Helpers
  // =============
  private static getStepMaxPrecision(
    props: HTMLInputProps & NumericInputProps,
    minimumFractionDigits: number = this.DEFAULT_MINIMUM_FRACTION_DIGITS
  ): number {
    const maxValue = props.minorStepSize != null ?
      Utils.countDecimalPlaces(props.minorStepSize) :
      Utils.countDecimalPlaces(props.stepSize);

    return minimumFractionDigits > maxValue ? minimumFractionDigits : maxValue;
  }

  private static getSanitizedValue(
    value: string,
    maximumFractionDigits: number,
    min: number,
    max: number,
    language: string,
    delta: number = 0,
    minimumFractionDigits: number = LocaleNumericInput.DEFAULT_MINIMUM_FRACTION_DIGITS,
    useGrouping: boolean = false
  ): string {
    const valueAsNumber = parseLocaleStringAsNumericValue(value);

    if (isNil(valueAsNumber)) {
      return LocaleNumericInput.VALUE_EMPTY;
    }

    const nextValue = toMaxPrecision(valueAsNumber + delta, maximumFractionDigits);

    return clampValue(nextValue, min, max)
      .toLocaleString(
        language,
        LocaleNumericInput.getLocaleOptions(
          minimumFractionDigits,
          maximumFractionDigits,
          useGrouping
        )
      );
  }

  private static getLocaleOptions = (
    minimumFractionDigits: number,
    maximumFractionDigits: number,
    useGrouping: boolean = false,
  ): Intl.NumberFormatOptions => {
    return {
      minimumFractionDigits,
      maximumFractionDigits,
      useGrouping,
    };
  }

  private static isValueUnset(value: string | number): boolean {
    return isNil(value) || value.toString() === LocaleNumericInput.VALUE_EMPTY;
  }

  public state: INumericInputState = {
    shouldSelectAfterUpdate: false,
    stepMaxPrecision: LocaleNumericInput.getStepMaxPrecision(
      this.props,
      LocaleNumericInput.DEFAULT_MINIMUM_FRACTION_DIGITS
    ),
    value: getValueOrEmptyValue(
      this.props.value,
      this.props.loc.language,
      LocaleNumericInput.getLocaleOptions(
        LocaleNumericInput.DEFAULT_MINIMUM_FRACTION_DIGITS,
        LocaleNumericInput.getStepMaxPrecision(
          this.props,
          LocaleNumericInput.DEFAULT_MINIMUM_FRACTION_DIGITS,
        )
      ),
    ),
    pendingIncrement: 0,
    isFocused: false,
  };

  // updating these flags need not trigger re-renders, so don't include them in this.state.
  private didPasteEventJustOccur: boolean = false;
  private delta: number = 0;
  private inputElement: HTMLInputElement | null = null;
  private intervalId: number | null = null;

  private incrementButtonHandlers = this.getButtonEventHandlers(IncrementDirection.UP);
  private decrementButtonHandlers = this.getButtonEventHandlers(IncrementDirection.DOWN);

  public render(): React.ReactNode {
    const { buttonPosition, className, fill, large } = this.props;
    const containerClasses = classNames(Classes.NUMERIC_INPUT, { [Classes.LARGE]: large }, className);
    const buttons = this.renderButtons();
    return (
      <StyledControlGroup className={containerClasses} fill={fill}>
        {buttonPosition === Position.LEFT && buttons}
        {
          !!this.props.errorHint &&
          <StyledErrorDiv className={classNames({ 'buttons-right': buttonPosition === Position.RIGHT })}>
            <div>
              <Tooltip2
                usePortal={true}
                content={this.props.errorHint}
              >
                <Icon icon='error' title={false}/>
              </Tooltip2>
            </div>
          </StyledErrorDiv>
        }
        {this.renderInput()}
        {buttonPosition === Position.RIGHT && buttons}
      </StyledControlGroup>
    );
  }

  public componentDidUpdate(prevProps: NumericInputProps, prevState: INumericInputState): void {
    super.componentDidUpdate(prevProps, prevState);

    if (this.state.shouldSelectAfterUpdate) {
      this.inputElement.setSelectionRange(0, this.state.value.length);
    }

    const didControlledValueChange = this.props.value !== prevProps.value;

    if (!didControlledValueChange && this.state.value !== prevState.value) {
      this.invokeValueCallback(this.state.value, this.props.onValueChange);
    }
  }

  protected validateProps(nextProps: HTMLInputProps & NumericInputProps): void {
    const { majorStepSize, max, min, minorStepSize, stepSize } = nextProps;
    if (min != null && max != null && min > max) {
      throw new Error(Errors.NUMERIC_INPUT_MIN_MAX);
    }
    if (stepSize == null) {
      throw new Error('NUMERIC_INPUT_STEP_SIZE_NULL');
    }
    if (stepSize <= 0) {
      throw new Error(Errors.NUMERIC_INPUT_STEP_SIZE_NON_POSITIVE);
    }
    if (minorStepSize && minorStepSize <= 0) {
      throw new Error(Errors.NUMERIC_INPUT_MINOR_STEP_SIZE_NON_POSITIVE);
    }
    if (majorStepSize && majorStepSize <= 0) {
      throw new Error(Errors.NUMERIC_INPUT_MAJOR_STEP_SIZE_NON_POSITIVE);
    }
    if (minorStepSize && minorStepSize > stepSize) {
      throw new Error(Errors.NUMERIC_INPUT_MINOR_STEP_SIZE_BOUND);
    }
    if (majorStepSize && majorStepSize < stepSize) {
      throw new Error(Errors.NUMERIC_INPUT_MAJOR_STEP_SIZE_BOUND);
    }
  }

  // Render Helpers
  // ==============

  private renderButtons(): React.ReactNode {
    const { intent } = this.props;
    const disabled = this.props.disabled || this.props.readOnly;
    return (
      <ButtonGroup className={Classes.FIXED} key='button-group' vertical={true}>
        <Button disabled={disabled} icon='chevron-up' intent={intent} {...this.incrementButtonHandlers} />
        <Button disabled={disabled} icon='chevron-down' intent={intent} {...this.decrementButtonHandlers} />
      </ButtonGroup>
    );
  }

  private renderInput(): React.ReactNode {
    const { large, ...inputGroupHtmlProps } = removeNonHTMLProps(this.props, NON_HTML_PROPS, true);

    return (
      <InputGroup
        style={this.props.style}
        autoFocus={this.props.autoFocus}
        autoComplete='off'
        {...inputGroupHtmlProps}
        intent={this.props.intent}
        inputRef={this.inputRef}
        onFocus={this.handleInputFocus}
        onBlur={this.handleInputBlur}
        onChange={this.handleInputChange}
        onKeyDown={this.handleInputKeyDown}
        onKeyPress={this.handleInputKeyPress}
        onPaste={this.handleInputPaste}
        leftElement={this.props.leftElement}
        rightElement={this.props.rightElement}
        value={this.state.value}
      />
    );
  }

  private inputRef = (input: HTMLInputElement | null): void => {
    this.inputElement = input;
    this.props.inputRefFunc?.(input);
  }

  // Callbacks - Buttons
  // ===================

  private getButtonEventHandlers(direction: IncrementDirection): ButtonEventHandlers {
    return {
      // keydown is fired repeatedly when held so it's implicitly continuous
      onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (isKeyboardClick(evt.keyCode)) {
          this.handleButtonClick(evt, direction);
        }
      },
      onMouseDown: (evt: React.MouseEvent<HTMLInputElement>) => {
        this.handleButtonClick(evt, direction);
        this.startContinuousChange();
      },
    };
  }

  private handleButtonClick = <ElementType extends HTMLInputElement = HTMLInputElement>(
    e: React.MouseEvent<ElementType> | React.KeyboardEvent<ElementType>,
    direction: IncrementDirection
  ): void => {
    const delta = this.updateDelta(direction, e);
    const nextValue = this.incrementValue(delta);
    this.invokeValueCallback(nextValue, this.props.onButtonClick);
  }

  private startContinuousChange(): void {
    // The button's onMouseUp event handler doesn't fire if the user
    // releases outside of the button, so we need to watch all the way
    // from the top.
    document.addEventListener('mouseup', this.stopContinuousChange);

    // Initial delay is slightly longer to prevent the user from
    // accidentally triggering the continuous increment/decrement.
    this.setTimeout(() => {
      this.intervalId = window.setInterval(this.handleContinuousChange, LocaleNumericInput.CONTINUOUS_CHANGE_INTERVAL);
    }, LocaleNumericInput.CONTINUOUS_CHANGE_DELAY);
  }

  private stopContinuousChange = (): void => {
    this.delta = 0;
    this.clearTimeouts();
    clearInterval(this.intervalId);
    document.removeEventListener('mouseup', this.stopContinuousChange);
  }

  private handleContinuousChange = (): void => {
    const nextValue = this.incrementValue(this.delta);
    this.invokeValueCallback(nextValue, this.props.onButtonClick);
  }

  // Callbacks - Input
  // =================

  private handleInputFocus = <ElementType extends HTMLInputElement = HTMLInputElement>(e: React.FocusEvent<ElementType>): void => {
    // update this state flag to trigger update for input selection (see componentDidUpdate)
    this.setState({
      shouldSelectAfterUpdate: this.props.selectAllOnFocus,
      isFocused: true,
    });
    this.props.onFocus?.(e);
  }

  private handleInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    // always disable this flag on blur so it's ready for next time.
    this.setState({
      shouldSelectAfterUpdate: false,
      isFocused: false,
    });

    if (this.props.clampValueOnBlur) {
      const sanitizedValue = this.getSanitizedValue(event.target.value);
      this.setState({ value: sanitizedValue });
    }

    this.props.onBlur?.(event);
  }

  private handleInputKeyDown = <ElementType extends HTMLInputElement = HTMLInputElement>(
    event: React.KeyboardEvent<ElementType>,
  ): void => {
    if (this.props.disabled || this.props.readOnly) {
      return;
    }

    const { keyCode } = event;

    // if the field is empty when pressing the keyboard up/down arrow, reset field to default value
    if (!this.state.value && (keyCode === Keys.ARROW_UP || keyCode === Keys.ARROW_DOWN)) {
      this.inputElement.blur();
      this.inputElement.focus();
      return;
    }

    let direction: IncrementDirection;

    if (keyCode === Keys.ARROW_UP) {
      direction = IncrementDirection.UP;
    } else if (keyCode === Keys.ARROW_DOWN) {
      direction = IncrementDirection.DOWN;
    }

    if (!isNil(direction)) {
      // when the input field has focus, some key combinations will modify
      // the field's selection range. we'll actually want to select all
      // text in the field after we modify the value on the following
      // lines. preventing the default selection behavior lets us do that
      // without interference.
      event.preventDefault();

      const delta = this.updateDelta(direction, event);
      this.incrementValue(delta);
    }

    this.props.onKeyDown?.(event);
  }

  private handleInputKeyPress = <ElementType extends HTMLInputElement = HTMLInputElement>(
    e: React.KeyboardEvent<ElementType>,
  ): void => {
    // we prohibit keystrokes in onKeyPress instead of onKeyDown, because
    // e.key is not trustworthy in onKeyDown in all browsers.
    if (this.props.allowNumericCharactersOnly && !isValidNumericKeyboardEvent(e)) {
      e.preventDefault();
    }

    this.props.onKeyPress?.(e);
  }

  private handleInputPaste = <ElementType extends HTMLInputElement = HTMLInputElement>(
    e: React.ClipboardEvent<ElementType>,
  ): void => {
    this.didPasteEventJustOccur = true;
    this.props.onPaste?.(e);
  }

  private handleInputChange = <ElementType extends HTMLInputElement = HTMLInputElement>(
    e: React.FormEvent<ElementType>,
  ): void => {
    const { value } = e.target as HTMLInputElement;

    let nextValue = value;
    if (this.props.allowNumericCharactersOnly && this.didPasteEventJustOccur) {
      this.didPasteEventJustOccur = false;
      const valueChars = value.split('');
      const sanitizedValueChars = valueChars.filter(isFloatingPointNumericCharacter);
      const sanitizedValue = sanitizedValueChars.join('');
      nextValue = sanitizedValue;
    }

    this.setState({ shouldSelectAfterUpdate: false, value: nextValue });
  }

  private invokeValueCallback(value: string, callback: (valueAsNumber: number, valueAsString: string) => void): void {
    callback?.(parseLocaleStringAsNumericValue(value), value);
  }

  private incrementValue(delta: number): string {
    // pretend we're incrementing from 0 if currValue is empty
    const currValue = this.state.value || LocaleNumericInput.VALUE_ZERO;
    const nextValue = this.getSanitizedValue(currValue, delta);
    let nextState: Partial<INumericInputState> = {
      shouldSelectAfterUpdate: this.props.selectAllOnIncrement,
      value: nextValue
    };

    if (LocaleNumericInput.isValueUnset(this.state.value)) {
      /**
       * If we had no value, but increment button was pressed,
       * We remember this increment action to add this to value, when we will have it.
       */
      nextState = {
        ...nextState,
        pendingIncrement: delta,
      };
    }

    this.setState(nextState as INumericInputState);

    return nextValue;
  }

  private getIncrementDelta(
    direction: IncrementDirection,
    isShiftKeyPressed: boolean,
    isAltKeyPressed: boolean,
  ): number {
    const { majorStepSize, minorStepSize, stepSize } = this.props;

    if (isShiftKeyPressed && majorStepSize != null) {
      return direction * majorStepSize;
    } else if (isAltKeyPressed && minorStepSize != null) {
      return direction * minorStepSize;
    } else {
      return direction * stepSize;
    }
  }

  private getSanitizedValue(value: string, delta: number = 0): string {
    return LocaleNumericInput.getSanitizedValue(
      value,
      this.state.stepMaxPrecision,
      this.props.min,
      this.props.max,
      this.props.loc.language,
      delta,
    );
  }

  private updateDelta<ElementType extends HTMLInputElement = HTMLInputElement>(
    direction: IncrementDirection,
    e: React.MouseEvent<ElementType> | React.KeyboardEvent<ElementType>
  ): number {
    this.delta = this.getIncrementDelta(direction, e.shiftKey, e.altKey);

    return this.delta;
  }
}
