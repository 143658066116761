// !!!IMPORTANT: Please keep in sync with contents of charting_library.d.ts
// When upgrading Trading View, please move the const enum constants from charting_library.d.ts file here removing the "declare" keyword
// Then import them in charting_library.d.ts as well as everywhere in the app from this file instead.

// These overrides are needed because we use Typescript loader with isolated modules (for faster compile times)
// and it does not support importing const enums from d.ts files. Please import all enums from here.
// It also seems that in production builds const enums don't work even when imported from these files so let's just declare them as regular enums

// From https://github.com/TypeStrong/ts-loader/issues/331#issuecomment-647171138
// "I assume some people may be disappointed that this is not a bug in ts-loader which can be fixed,
// or that isolatedModules cannot simply be switched off. transpileOnly is a great feature which
// greatly reduces compile time in large projects but it is mutually exclusive with exported const enums."

export enum StopType {
  StopLoss = 0,
  TrailingStop = 1,
  GuaranteedStop = 2,
}
export enum ChartStyle {
  Bar = 0,
  Candle = 1,
  Line = 2,
  Area = 3,
  Renko = 4,
  Kagi = 5,
  PnF = 6,
  LineBreak = 7,
  HeikinAshi = 8,
  HollowCandle = 9,
  Baseline = 10,
  HiLo = 12,
  Column = 13,
  LineWithMarkers = 14,
  Stepline = 15,
  HLCArea = 16,
  VolCandle = 19,
}
export enum ConnectionStatus {
  Connected = 1,
  Connecting = 2,
  Disconnected = 3,
  Error = 4,
}
export enum NotificationType {
  Error = 0,
  Success = 1,
}
export enum OrderStatus {
  Canceled = 1,
  Filled = 2,
  Inactive = 3,
  Placing = 4,
  Rejected = 5,
  Working = 6,
}
export enum OrderStatusFilter {
  All = 0,
  Canceled = 1,
  Filled = 2,
  Inactive = 3,
  Rejected = 5,
  Working = 6,
}
export enum OrderTicketFocusControl {
  LimitPrice = 1,
  StopPrice = 2,
  TakeProfit = 3,
  StopLoss = 4,
  Quantity = 5,
}
export enum OrderType {
  Limit = 1,
  Market = 2,
  Stop = 3,
  StopLimit = 4,
}
export enum ParentType {
  Order = 1,
  Position = 2,
  IndividualPosition = 3,
}
export enum PriceScaleMode {
  /** Normal mode of the price scale */
  Normal = 0,
  /** Logarithmic mode of the price scale */
  Log = 1,
  /** Percentage mode of the price scale */
  Percentage = 2,
  /** Indexed to 100 mode of the price scale */
  IndexedTo100 = 3,
}
export enum Side {
  Buy = 1,
  Sell = -1,
}
